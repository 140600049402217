import { useState, useEffect } from 'react';
import { Row, Col, Card } from 'reactstrap';

type props = {
  tokenData?: any;
};

export const DataTime = ({ tokenData }: props) => {
  const locale = 'en';
  const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
    setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 1 * 1000);
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: 'long' });

  const dateDay = today.getDate();
  const month = `${today.toLocaleDateString(locale, {
    month: 'short',
  })}`;

  const year = `${today.getFullYear()}`;

  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'} `;

  const time = today.toLocaleTimeString(locale, {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  });

  return (
    <>
      <Card className="kiosk-time-card">
        {tokenData?.school?.frachiseePicture ? (
          <Row className="mb-3">
            <Col>
              <img src={tokenData?.school?.frachiseePicture || ''} style={{ maxWidth: 150 }} />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row>
          <Col>{wish}</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <span className="kiosk-day">{day}</span>,{' '}
            <span className="kiosk-date">{`${month} ${dateDay} ${year}`}</span>,{' '}
            <span className="kiosk-date">{time}</span>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default DataTime;
