import { Row, Col, Card } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import KioskService from '../../../services/Kiosk';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import Timer from 'src/components/Core/Timer';
import moment from 'moment';

type props = {
  dataResult: any;
  setNewShowUserRow: (type: boolean) => void;
};

const KioskUser = ({ dataResult, setNewShowUserRow }: props) => {
  const { token }: any = useContext(AuthContext);

  // const tokenKiosk = sessionStorage.getItem('arbitrium_token') || '';
  const tokenKiosk = localStorage.getItem('arbitrium_token') || '';

  const [userIsClockedIn, setUserIsClockedIn] = useState(false);
  const [userIsBreaked, setUserIsBreaked] = useState(false);
  const [timerStart, setTimerStart] = useState<undefined | Date>(undefined);

  const customStyles = {
    statusIcon: { verticalAlign: 'baseline', fontSize: '25px' },
  };

  const [dataKids, setDataKids] = useState([] as any);

  const returnKeypad = async () => {
    setNewShowUserRow(false);
  };

  const checkInKids = async (kidsId: string, userId: string) => {
    try {
      await KioskService(token).checkInKids(kidsId, userId, tokenKiosk);
      toast.success(`Check IN successfully!`);
      returnKids();
    } catch (e) {
      toast.error('Unable to Check In');
    }
  };

  const checkOutKids = async (kidsId: string, userId: string) => {
    try {
      await KioskService(token).checkOutKids(kidsId, userId, tokenKiosk);
      toast.success(`Check Out successfully!`);
      returnKids();
    } catch (e) {
      toast.error('Unable to Check Out');
    }
  };

  const clockInUser = async (userId: string) => {
    try {
      const result = await KioskService(token).clockInUsers(userId, tokenKiosk);

      if (result?.data?.isSuccess === true) {
        setUserIsClockedIn(true);
        toast.success(`Clock IN successfully!`);
      } else {
        setUserIsClockedIn(false);
        toast.error('Unable to Clock In');
      }
    } catch (e) {
      setUserIsClockedIn(false);
      toast.error('Unable to Clock In');
    }
  };

  const clockOutUser = async (userId: string) => {
    try {
      const result = await KioskService(token).clockOutUsers(userId, tokenKiosk);
      if (result?.data?.isSuccess === true) {
        setUserIsClockedIn(false);
        toast.success(`Clock OUT successfully!`);
      } else {
        setUserIsClockedIn(false);
        toast.error('Unable to Clock Out');
      }
    } catch (e) {
      setUserIsClockedIn(false);
      toast.error('Unable to Clock Out');
    }
  };

  const startBreakUser = async (userId: string) => {
    try {
      const result = await KioskService(token).breakUsers(userId, tokenKiosk);
      if (result?.data?.isSuccess === true) {
        setUserIsBreaked(true);
        setTimerStart(new Date());
        toast.success(`Start break successfully!`);
      } else {
        setUserIsClockedIn(false);
        toast.error('Unable to start break');
      }
    } catch (e) {
      setUserIsClockedIn(false);
      toast.error('Unable to start break');
    }
  };

  const endBreakUser = async (userId: string) => {
    try {
      const now = moment();
      const start = moment(timerStart);
      const duration = moment.duration(now.diff(start));
      const hours = duration.asHours();

      if (hours < 1) {
        return toast.warn(`Your break needs to be one hour.`);
      }
      const result = await KioskService(token).breakUsers(userId, tokenKiosk);
      if (result?.data?.isSuccess === true) {
        setUserIsBreaked(false);
        toast.success(`End break successfully!`);
      } else {
        setUserIsClockedIn(false);
        toast.error('Unable to end break');
      }
    } catch (e) {
      setUserIsClockedIn(false);
      toast.error('Unable to end break');
    }
  };

  const dataUser = {
    userId: dataResult?.data?.id,
    name: dataResult?.data?.firstName ? `${dataResult.data?.lastName}, ${dataResult.data?.firstName}` : '',
    img: dataResult?.data?.picture
      ? `${dataResult.data?.picture}`
      : require('../../../assets/img/user-default.png').default,
    isStaff: dataResult?.data?.isStaff,
    isClockedIn: dataResult?.data?.isClockedIn,
    isBreaked: dataResult?.data?.isBreaked,
    lastBreak: dataResult?.data?.lastBreak?.createdAt || undefined,
  };

  const userId = dataUser?.userId;

  const returnKids = async () => {
    try {
      const result = await KioskService(token).show(userId);
      setDataKids(result?.data);
    } catch (e) {
      toast.error('Error');
    }
  };

  useEffect(() => {
    returnKids();
  }, [userId]);

  useEffect(() => {
    setUserIsClockedIn(dataUser?.isClockedIn);
    setUserIsBreaked(dataUser?.isBreaked);
    setTimerStart(dataUser?.lastBreak ? new Date(dataUser?.lastBreak) : undefined);
  }, []);

  return (
    <>
      <Row className="kiosk-user-main">
        <Col>
          <Row className="kiosk-button-return">
            <Col>
              <button type="button" className="btn btn-primary btn-md" onClick={() => returnKeypad()}>
                Return
              </button>
            </Col>
          </Row>
          {dataUser.isStaff == true && (
            <Row className="mt-2">
              <Col>
                <Card className="kiosk-card-user">
                  <Row className="">
                    <Col lg="4">
                      <Row>
                        <img
                          alt={`${dataUser.name}`}
                          title={`${dataUser.name}`}
                          className="rounded-circle"
                          src={dataUser?.img}
                          style={{ width: 80, height: 80, margin: '0 auto', textAlign: 'center' }}
                        />
                      </Row>
                      <Row>
                        <span className="h2" style={{ textAlign: 'center', margin: '0 auto' }}>
                          {dataUser.name}
                        </span>
                      </Row>
                    </Col>

                    <Col lg="3" className="kiosk-icon-checkin-checkout">
                      <span className="h2 " style={{ textAlign: 'center', margin: '0 auto' }}>
                        <i
                          className={`far ${
                            userIsClockedIn
                              ? 'fa-arrow-alt-circle-right text-success'
                              : 'fa-arrow-alt-circle-left text-danger'
                          } mr-1 fa-xl`}
                          style={customStyles.statusIcon}
                        />
                        {userIsClockedIn ? 'Clocked In' : 'Clocked Out'}
                      </span>
                    </Col>
                    <Col lg="5" className="kiosk-button-make-checkin-checkout">
                      {userIsClockedIn == true && (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary btn-md"
                            onClick={() =>
                              !userIsBreaked ? startBreakUser(dataUser.userId) : endBreakUser(dataUser.userId)
                            }
                          >
                            {!userIsBreaked ? 'Start Break' : 'End Break'}
                            {userIsBreaked ? <Timer startTime={timerStart || new Date()}></Timer> : <></>}
                          </button>
                          {!userIsBreaked ? (
                            <button
                              type="button"
                              className="btn btn-danger btn-md"
                              onClick={() => clockOutUser(dataUser.userId)}
                            >
                              Clock OUT
                            </button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {userIsClockedIn == false && (
                        <button
                          type="button"
                          className="btn btn-success btn-md"
                          onClick={() => clockInUser(dataUser.userId)}
                        >
                          Clock IN
                        </button>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          {dataKids.map((r: any, index: number) => {
            return (
              <Row key={`activity-${index}`} className="mt-2">
                <Col>
                  <Card className="kiosk-card-kids">
                    <Row className="">
                      <Col lg="4">
                        <Row>
                          <img
                            alt={`${r?.kids?.lastName}`}
                            title={`${r?.kids?.lastName}`}
                            className="rounded-circle"
                            src={r?.kids?.picture}
                            style={{ width: 80, height: 80, margin: '0 auto', textAlign: 'center' }}
                          />
                        </Row>

                        <Row>
                          <span className="h2 " style={{ textAlign: 'center', margin: '0 auto' }}>
                            {r?.kids?.lastName}, {r?.kids?.firstName}
                          </span>
                        </Row>
                      </Col>

                      <Col lg="3" className="kiosk-icon-checkin-checkout">
                        <span className="h2 " style={{ textAlign: 'center', margin: '0 auto' }}>
                          <i
                            className={`far ${
                              r?.isCheckedIn
                                ? 'fa-arrow-alt-circle-right text-success'
                                : 'fa-arrow-alt-circle-left text-danger'
                            } mr-1 fa-xl`}
                            style={customStyles.statusIcon}
                          />
                          {r?.isCheckedIn ? 'Checked In' : 'Checked Out'}
                        </span>
                      </Col>
                      <Col lg="5" className="kiosk-button-make-checkin-checkout">
                        {r?.isCheckedIn == true && (
                          <button
                            type="button"
                            className="btn btn-danger btn-md"
                            onClick={() => checkOutKids(r?.kids?.kidsId, dataUser.userId)}
                          >
                            Check OUT
                          </button>
                        )}
                        {r?.isCheckedIn == false && (
                          <button
                            type="button"
                            className="btn btn-success btn-md"
                            onClick={() => checkInKids(r?.kids?.kidsId, dataUser.userId)}
                          >
                            Check IN
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </>
  );
};
export default KioskUser;
