import { useStopwatch } from 'react-timer-hook';

const Timer = ({ startTime }: { startTime: Date }) => {
  const now = new Date();
  const diff = now.getTime() - startTime.getTime();
  const current = new Date(now.getTime() + diff);
  const { seconds, minutes, hours } = useStopwatch({ offsetTimestamp: current, autoStart: true });

  return (
    <div>
      <span>{String(hours).padStart(2, '0')}</span>:<span>{String(minutes).padStart(2, '0')}</span>:
      <span>{String(seconds).padStart(2, '0')}</span>
    </div>
  );
};

export default Timer;
